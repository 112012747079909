<template>
  <button
    class="btn-copy-to-clipboard"
    type="button"
    :title="`${$t('article.copyId')} - ${data}`"
    @click="copyToClipboard($event)"
  >
    <span class="btn-copy-to-clipboard__content">
      <IconCopy class="btn-copy-to-clipboard__icon" />
    </span>
  </button>
</template>

<script>
import ClipboardService from '@/services/ClipboardService'
import NotifyService from '@/services/NotifyService'
import IconCopy from '@/assets/img/svg/copy.svg?inline'

export default {
  name: 'BtnCopyToClipboard',
  props: {
    label: {
      type: String,
      default: ''
    },
    data: {
      type: [String, Number, Object]
    },
    notifyMessage: {
      type: String
    }
  },
  components: {
    IconCopy
  },
  methods: {
    copyToClipboard (event) {
      ClipboardService.copy(this.data, event)
      NotifyService.setSuccessMessage(this.notifyMessage)
    }
  }
}
</script>

<style scoped lang="scss">
.btn-copy-to-clipboard {
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
  &__content {
    background: #fff;
    width: rem(30px);
    height: rem(30px);
    border-radius: rem(4px);
    border: 1px solid #D1DBE4;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      border: 1px solid #6599FE;
      .btn-copy-to-clipboard__icon {
        fill: #6599FE;
      }
    }
  }
  &__icon {
    width: rem(14px);
    height: auto;
    fill: #9DAAC4;
  }
}
</style>
